*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  height: 100vh;
}
body{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
}

.main{
  display: flex;
  height: 100vh;
}


.container{
  flex-grow: 1;
  padding: 2em;
  background-image: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .8)), url('../public/pexels-photo-1229861.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title{
  font-size: 7em;
}
.info{
  font-size: 2em;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 1.5em;
  color: rgb(224, 224, 224);
}
.btn{
  margin: 0 auto;
  border: none;
  outline: none;
  padding: .75em 1em;
  font-size: 1em;
  letter-spacing: 1px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: rgb(134, 49, 0);
  color: #fff;
}

.sidebar{
  width: 260px;
  flex-shrink: 0;
  background-color: rgb(22, 22, 22);
  height: 100%;
  overflow: auto;
}


.sidebar-item{
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
}
.sidebar-item:hover{
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-title{
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span i{
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn{
  cursor: pointer;
  transition: transform .3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-content{
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content{
  height: auto;
}

.sidebar-item.plain{
  color: #fff;
  text-decoration: none;
}
.sidebar-item.plain:hover{
  text-decoration: underline;
}
.sidebar-item.plain i{
  display: inline-block;
  width: 1.7em;
}